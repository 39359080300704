import {useEffect} from 'react';
import {State} from './data';
import moment from 'moment';
import * as option from '@/config/constants/options';

// 年が0始まりの場合、無効な日付と判断する
const INVALID_DATES = /000\d+/;
const MAX_PRICE = 2147483647;

/** 日付フォーマット */
export const format = (base: string, format?: string) => {
  const dt = moment(base).format(format);

  if (dt === 'Invalid date') return '';

  return dt;
};

const getFishingDate = (date: string) => {
  // 無効な日付
  if (INVALID_DATES.test(date))
    return {
      year: '',
      month: '',
      day: '',
    };

  return {
    year: format(date, 'YYYY'),
    month: parseInt(format(date, 'M'), 10),
    day: parseInt(format(date, 'D'), 10),
  };
};

export const useSetData = (
  data: StocksStore,
  detail: DetailStore,
  setInitialValues: React.Dispatch<React.SetStateAction<State>>
) => {
  useEffect(() => {
    const {
      fixPrice,
      closeBidMin,
      fishingDay,
      imageJson,
      repeatLimitDate,
      shippingSize,
      count,
      id,
    } = data;

    const {weight, pesticidesAdditionalInfo, soilAdditionalInfo, size} = detail;

    // 追加
    // 肥料分解
    const soilAdditionalInfoValue = (() => {
      if (!soilAdditionalInfo)
        return option.soilAdditionalInfo.map((_) => false);

      const trues = soilAdditionalInfo.split(',');
      return option.soilAdditionalInfo.map((e) => trues.includes(e.value));
    })();

    // 無農薬の分解
    const pesticidesAdditionalInfoValue = (() => {
      if (detail.pesticides === 2) return detail.pesticidesAdditionalInfo;
      else if (detail.pesticides === 3) {
        const trues = pesticidesAdditionalInfo.split(',');
        return option.pesticidesAdditionalInfo.map((e) =>
          trues.includes(e.value)
        );
      }
    })();

    const updateData = {
      ...data,
      ...detail,
      id,
      fixPrice: Number(fixPrice) === MAX_PRICE ? '' : fixPrice,
      closeBidMin: Number(closeBidMin) / 60,
      size: size ? size : '',
      weight: weight ? weight : '',
      fishingDate: getFishingDate(fishingDay),
      shippingSize: shippingSize ? shippingSize : 60,
      imageJson: imageJson || [],
      repeatLimitDate: {
        year: format(repeatLimitDate, 'YYYY'),
        month: parseInt(format(repeatLimitDate, 'M'), 10),
        day: parseInt(format(repeatLimitDate, 'D'), 10),
      },
      count,
      // 追加
      soilAdditionalInfo: soilAdditionalInfoValue,
      pesticidesAdditionalInfo: detail.pesticidesAdditionalInfo,
      pesticidesAdditionalInfoCheck:
        detail.pesticides === 3
          ? pesticidesAdditionalInfoValue
          : [false, false, false, false, false],
      pesticidesAdditionalInfoText:
        detail.pesticides === 2 ? pesticidesAdditionalInfoValue : '',
    };

    setInitialValues(updateData);
  }, [data, detail, setInitialValues]);
};
