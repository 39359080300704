import {get} from '@/lib/utils/env';
import utils from '@/lib/utils';
import qs from 'qs';

type ExportCsvParams = {
  sale_id: number;
}[];

export const exportCsv = async (qp: any) => {
  const {SITE_TYPE} = get();
  const _qp = qs.stringify({sale_id: qp}, {indices: false});
  const res = await utils.axios.api().get(`/v1/${SITE_TYPE}/sales_csv?${_qp}`);

  return res.data;
};
