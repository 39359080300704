import {get} from '@/lib/utils/env';
import utils from '@/lib/utils';

const {SITE_TYPE} = get();

/** 自動再出品データの登録 */
export const createStocks = (params: any) =>
  utils.axios
    .api()
    .post(`/v1/${SITE_TYPE}/stocks`, utils.mapKeysSnakeCase.default(params));

/** 在庫データ更新 */
export const updateStocks = async (stockId: number, body: any) => {
  try {
    const res = await utils.axios
      .api()
      .put(
        `/v1/${SITE_TYPE}/stocks/${stockId}`,
        utils.mapKeysSnakeCase.default(body)
      );

    return res;
  } catch (error: any) {
    console.error(error);
  }
};

/** 在庫データ取得 */
export const getOneStock = async (stockId: number) => {
  try {
    const res = await utils.axios
      .api()
      .get(`/v1/${SITE_TYPE}/stocks/${stockId}`);

    return res;
  } catch (error: any) {
    console.error(error);
  }
};

// 追加-在庫詳細データ取得
export const getStockDetail = async (stockId: number) => {
  try {
    const res = await utils.axios
      .api()
      .get(`/v1/${SITE_TYPE}/stocks/detail/${stockId}`);

    return res;
  } catch {
    console.error;
  }
};

/** ページネーション */
export const getStocksPaging = async (params: GetStocksPagingParams) => {
  try {
    const res = await utils.axios
      .api()
      .get(`/v1/${SITE_TYPE}/stocks_paging`, {params});
    return res;
  } catch (error: any) {
    console.error(error);
  }
};

/** 取り下げ */
export const updateWithDraw = async (
  stockId: number,
  body: UpdateStocksAuto
) => {
  try {
    const res = await utils.axios
      .api()
      .put(
        `/v1/${SITE_TYPE}/stocks_auto/${stockId}`,
        utils.mapKeysSnakeCase.default(body)
      );

    return res;
  } catch (error: any) {
    console.error(error);
  }
};
