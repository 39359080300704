import paths from '@/config/paths';
import {mergeDate} from '@/lib/utils/date';
import notify from '@/migration/notification';
import {updateStocks} from '@/api/stocks';

type StockParams = {
  area: number;
  capacity: number;
  cleaningMethod: number;
  closeBidMin: number;
  count: number;
  deliveryMethod: number;
  explanation: string;
  fishingDay: string;
  fishingSpot: number;
  fishingSpotDetail: string;
  fixPrice?: number;
  ikejimeMethod: number;
  ikejimeTiming: number;
  ikejimeWater: number;
  imageJson: string[];
  isDummy: boolean;
  isIkejime: boolean;
  isLandAfterFix: boolean;
  isNature: boolean;
  isVacuum: boolean;
  repeatLimitDate: string;
  savingMethod: number;
  sellerUserId: number;
  shippingExpense: number;
  shippingSize: number;
  shippingTiming?: number;
  size: number;
  startPrice: number;
  weight?: number;
  weightAdditionalInfo: string;

  // 追加
  name: string;
  breed: string;
  isUnevenness: boolean;
  producer: string;
  grade: number;
  origin: number;
  originAdditionalInfo: string;
  averageSugarContent: number;
  harvestDate: string;
  harvestTiming: number;
  saveMethodUser: number;
  saveMethodSeller: number;
  saveMethodUserAdditionalInfo: string;
  cultivationMethod: number;
  soil: string;
  soilAdditionalInfo: string;
  pesticides: string;
  pesticidesAdditionalInfo: string;
  genetic: number;
  jas: number;
  individualObligations: string;
  globalGap: string | null;
};

const toInt = (str: string) => {
  return str ? parseInt(str, 10) : 0;
};

const createParams = (values: any) => {
  let fishingDay = '';

  if (
    values.fishingDate.year &&
    values.fishingDate.month &&
    values.fishingDate.day
  ) {
    fishingDay = mergeDate(
      values.fishingDate.year,
      values.fishingDate.month,
      values.fishingDate.day,
      'YYYY-MM-DD'
    );
  }

  const soilInfo = values.soilAdditionalInfo
    ? values.soilAdditionalInfo
        .map((e: any, i: number) => (e ? (i + 1).toString() : undefined))
        .filter((f: undefined | string) => f !== undefined)
        .join(',')
    : null;

  const pesticidesInfo = (() => {
    switch (values.pesticides) {
      case 2:
        return values.pesticidesAdditionalInfoText;
      case 3:
        return values.pesticidesAdditionalInfoCheck
          .map((e: any, i: number) => (e ? (i + 1).toString() : undefined))
          .filter((f: undefined | string) => f !== undefined)
          .join(',');
      default:
        return null;
    }
  })();

  const stockParams: StockParams = {
    area: values.area,
    capacity: toInt(values.capacity),
    cleaningMethod: values.cleaningMethod,
    closeBidMin: values.closeBidMin * 60,
    count: 0,
    deliveryMethod: values.deliveryMethod,
    explanation: values.explanation,
    fishingDay,
    fishingSpot: toInt(values.fishingSpot),
    fishingSpotDetail: values.fishingSpotDetail,
    ikejimeMethod: values.ikejimeMethod,
    ikejimeTiming: values.ikejimeTiming,
    ikejimeWater: values.ikejimeWater,
    imageJson: values.imageJson,
    isDummy: values.isDummy,
    isIkejime: values.isIkejime,
    isLandAfterFix: values.isLandAfterFix,
    isNature: values.isNature,
    isVacuum: values.isVacuum,
    repeatLimitDate: mergeDate(
      values.repeatLimitDate.year,
      values.repeatLimitDate.month,
      values.repeatLimitDate.day,
      'YYYY-MM-DD'
    ),
    savingMethod: values.savingMethod,
    sellerUserId: values.sellerUserId,
    shippingExpense: values.shippingExpense,
    shippingSize: values.shippingSize,
    shippingTiming: values.shippingTiming,
    size: toInt(values.size),
    startPrice: toInt(values.startPrice),
    weightAdditionalInfo: values.weightAdditionalInfo,

    // 追加
    name: values.name,
    breed: values.breed,
    isUnevenness: values.isUnevenness,
    producer: values.producer,
    grade: values.grade,
    origin: values.origin,
    originAdditionalInfo: values.originAdditionalInfo,
    averageSugarContent: parseFloat(values.averageSugarContent),
    harvestTiming: values.harvestTiming,
    harvestDate: fishingDay,
    saveMethodUser: values.saveMethodUser,
    saveMethodSeller: values.saveMethodSeller,
    saveMethodUserAdditionalInfo: values.saveMethodUserAdditionalInfo,
    cultivationMethod: values.cultivationMethod,
    soil: values.soil,
    soilAdditionalInfo: soilInfo,
    pesticides: values.pesticides,
    pesticidesAdditionalInfo: pesticidesInfo,
    genetic: values.genetic,
    jas: values.jas,
    individualObligations: values.individualObligations,
    globalGap: values.globalGap ? '認証' : null,
  };

  if (values.weight) stockParams.weight = toInt(values.weight);

  if (values.fixPrice) stockParams.fixPrice = toInt(values.fixPrice);

  if (values.shippingTiming === 3)
    stockParams.shippingExpense = values.shippingTiming;

  return {stockParams};
};

export const submit = async (
  stockId: number,
  value: any,
  actions: any,
  history: any,
  setModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    actions.setSubmitting(true);

    const {stockParams} = createParams(value);

    await updateStocks(stockId, stockParams);

    notify('在庫の変更内容を保存しました。', 'success');
    history.push(paths.stocks.main);
  } catch (error: any) {
    notify(error.message, 'error');
  } finally {
    actions.setSubmitting(false);
    setModal(false);
  }
};
