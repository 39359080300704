import {get} from '@/lib/utils/env';
import utils from '@/lib/utils';

const {SITE_TYPE} = get();
const resource = `/v1/${SITE_TYPE}/sales`;

export type GetChatMessage = {
  saleId: number;
  limit: number;
  page: number;
};

// 出品商品単体削除
export const deleteSale = async (saleId: number, lastUpdatedAt: string) => {
  await utils.axios
    .api()
    .delete(`${resource}/${saleId}?last_updated_at=${lastUpdatedAt}`);
};

/** 落札後チャット登録 */
export const postChatMessage = async (saleId: number, message: string) => {
  try {
    const data = await utils.axios
      .api()
      .post(`${resource}/${saleId}/comments`, {
        message,
      });

    return data;
  } catch (error: any) {
    return error;
  }
};

/** 落札後チャット取得 */
export const getChatMessages = async ({
  saleId,
  limit,
  page,
}: GetChatMessage): Promise<any> => {
  try {
    const {data, headers} = await utils.axios
      .api()
      .get(`${resource}/${saleId}/comments_paging?limit=${limit}&page=${page}`);

    return {data, headers};
  } catch (error: any) {
    return error;
  }
};

/** 質問・コメント登録 */
export const postCommentMessage = async (saleId: number, message: string) => {
  try {
    const data = await utils.axios
      .api()
      .post(`${resource}/${saleId}/comments/before_fixed`, {
        message,
      });

    return data;
  } catch (error: any) {
    return error;
  }
};

/** 落札後チャット取得 */
export const getCommentMessages = async ({
  saleId,
  limit,
  page,
}: GetChatMessage): Promise<any> => {
  try {
    const {data, headers} = await utils.axios
      .api()
      .get(
        `${resource}/${saleId}/comments_paging/before_fixed?limit=${limit}&page=${page}`
      );

    return {data, headers};
  } catch (error: any) {
    return error;
  }
};

export type SalesPagingParams = {
  limit: number;
  page: number;
  sort: string;
  closed: number[];
  fixed?: number;
  seller: number;
  search?: {words: string[]};
};

/**
 * 出品した商品ページング取得
 */
export const salesPaging = async (qp: SalesPagingParams): Promise<any> => {
  const keyword = qp.search?.words;
  delete qp.search;
  const res = await utils.axios
    .api()
    .get(`/v1/${SITE_TYPE}/sales_paging`, {params: {...qp, keyword}});
  const totalCount = res.headers['x-total-count']
    ? Number(res.headers['x-total-count'])
    : 0;
  const totalPages = res.headers['x-total-pages']
    ? Number(res.headers['x-total-pages'])
    : 0;

  return {...res.data, totalCount, totalPages};
};

/*
 * 出品した商品単体取得
 */
export const getOne = async (id: number) => {
  const res = await utils.axios.api().get(`/v1/${SITE_TYPE}/sales/${id}`);

  return res.data;
};
