import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import {Image} from 'semantic-ui-react';

import constants from '@/config/constants';
import {Props} from './types';

import './index.scss';

ReactModal.setAppElement('body');

const _Base: React.FC<Props> = (props) => {
  return (
    <ReactModal
      className={classNames('a__modal-base', {
        xs: props.size === 'xs',
        sm: props.size === 'sm',
        md: props.size === 'md',
      })}
      isOpen={props.isModal}
      contentLabel="ModalWrapper"
      onRequestClose={() => props.handleCloseModal(!props.isModal)}
      overlayClassName="a__overlay"
      shouldCloseOnOverlayClick={props.isAutoClose}
    >
      {props.hideCloseIcon ? null : (
        <div
          className="w__close"
          onClick={() => props.handleCloseModal(!props.isModal)}
          role="button"
          tabIndex={0}
        >
          <Image src={constants.IMAGES.CLOSE_MODAL} />
        </div>
      )}

      <div className="w__title">
        <h1>{props.title}</h1>
      </div>
      {props.children}
    </ReactModal>
  );
};

export default _Base;
