import React, {useEffect} from 'react';
import utils from '@/lib/utils';
import {BrowserRouter, useLocation} from 'react-router-dom';
import Routes from '@/routes';

utils.amplify.init();

function ScrollToTop() {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes />
    </BrowserRouter>
  );
};
