import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';

import api from '@/api';
import _ from 'lodash';
import utils from '@/lib/utils';
import {
  regions,
  sizes,
  Region,
  Column,
  RelayColumn,
} from '@/templates/ShippingCosts/Main/Columns';

export type ShippingCostsState = {
  sizeCosts: Column[];
  originalData: Column[];
  relayCosts: RelayColumn[];
  relayOriginalData: RelayColumn[];
  loading: boolean;
  error: string | null;
};

const initialState: ShippingCostsState = {
  sizeCosts: [],
  originalData: [],
  relayCosts: [],
  relayOriginalData: [],
  loading: false,
  error: null,
};

export const fetchShippingCosts = createAsyncThunk(
  'shippingCosts/fetchShippingCosts',
  async (userId: number) => {
    const sizeRes = await api.shippingCosts.getShippingCosts(userId);
    const sizeData = utils.mapKeysCamelCase.default(sizeRes.data);

    const relayRes = await api.shippingRelayCosts.getShippingRelayCosts();
    const relayData = utils.mapKeysCamelCase.default(relayRes.data);

    const sizeCosts: any[] = [];
    const relayCosts: any[] = [];
    const relayColumn: any = {};

    _.map(sizes, (size) => {
      const column: Partial<Column> = {};
      column.size = size;

      _.map(regions, (value: any, key: keyof Region) => {
        // 文字列連結をすると string 型になってしまうためキャスト
        const dataKey = `${key}${size}` as keyof typeof sizeData;
        column[key] = String(sizeData[dataKey]);
      });

      sizeCosts.push(column);
    });

    // FIXME: 離島の表示方法変更時に大幅な改修が必要
    _.map(relayData, (value: any, key: ShippingRelayCostRegion) => {
      const cost = value[0];

      relayColumn[key] = {
        remoteIslandGroupId: cost.remoteIslandGroupId,
        relayCost: String(cost.relayCost),
      };
    });

    relayCosts.push(relayColumn);

    return {sizeCosts, relayCosts};
  }
);

export const updateShippingCosts = createAsyncThunk(
  'shippingCosts/updateShippingCosts',
  async ({
    userId,
    sizeCosts,
    relayCosts,
  }: {
    userId: number;
    sizeCosts: ShippingCostsState['sizeCosts'];
    relayCosts: ShippingCostsState['relayCosts'];
  }) => {
    const body: UpdateSippingCostsBody = {};

    _.map(sizeCosts, (column) => {
      _.map(column, (value, key) => {
        if (key !== 'size') {
          // NOTE: mapKeysSnakeCaseをクエリパラメータ全体に使うと minamiTohoku120 が minami_tohoku_120 になってしまう
          // 期待値の minami_tohoku120 にするためここで指定
          // @ts-ignore
          const paramKey = `${_.snakeCase(key)}${
            column.size
          }` as keyof UpdateSippingCostsBody;
          body[paramKey] = Number(value);
        }
      });
    });

    await api.shippingCosts.updateShippingCosts(userId, body);

    const relayBody: UpdateSippingRelayCostsBody = {userId, items: []};
    _.forEach(relayCosts, (columns) => {
      _.map(columns, (value) => {
        const item = {
          remoteIslandGroupId: value.remoteIslandGroupId,
          relayCost: Number(value.relayCost),
        };
        relayBody.items.push(item);
      });
    });
  }
);

const shippingCosts = createSlice({
  name: 'shippingCosts',
  initialState,
  reducers: {
    updateCurrentData: (
      state,
      {
        payload,
      }: PayloadAction<{rowIndex: number; columnId: string; value: string}>
    ) => {
      state.sizeCosts = state.sizeCosts.map((row, index) => {
        if (index === payload.rowIndex) {
          return {
            ...state.sizeCosts[payload.rowIndex],
            [payload.columnId]: payload.value,
          };
        }

        return row;
      });
    },
    updateCurrentRelayData: (
      state,
      {
        payload,
      }: PayloadAction<{
        rowIndex: number;
        columnId: string;
        value: string;
      }>
    ) => {
      state.relayCosts = state.relayCosts.map((row, index) => {
        if (index === payload.rowIndex) {
          const row = state.relayCosts[payload.rowIndex];
          return {
            ...state.relayCosts[payload.rowIndex],
            [payload.columnId]: {
              ...row[payload.columnId as ShippingRelayCostRegion],
              relayCost: payload.value,
            },
          };
        }

        return row;
      });
    },
    resetCurrentData: (state) => {
      state.sizeCosts = state.originalData;
      state.relayCosts = state.relayOriginalData;
    },
    updateError: (
      state,
      {payload}: PayloadAction<{error: ShippingCostsState['error']}>
    ) => {
      state.error = payload.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShippingCosts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchShippingCosts.fulfilled, (state, {payload}) => {
      state.sizeCosts = payload.sizeCosts;
      state.originalData = payload.sizeCosts;
      state.relayCosts = payload.relayCosts;
      state.relayOriginalData = payload.relayCosts;
      state.loading = false;
    });
    builder.addCase(fetchShippingCosts.rejected, (state) => {
      state.sizeCosts = [];
      state.loading = false;
    });
    builder.addCase(updateShippingCosts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateShippingCosts.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateShippingCosts.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  updateCurrentData,
  updateCurrentRelayData,
  resetCurrentData,
  updateError,
} = shippingCosts.actions;

export default shippingCosts.reducer;
