import React from 'react';

export const CSVExplanation = () => {
  return (
    <div className="a_csv-explanation">
      <div className="item">
        <div className="item-title">商品名</div>
        <div className="item-body">
          出品登録時に入力頂いた競りタイトルです。
        </div>
      </div>
      <div className="item">
        <div className="item-title">出品日時</div>
        <div className="item-body">出品登録時点の日時です。</div>
      </div>
      <div className="item">
        <div className="item-title">終了日時</div>
        <div className="item-body">落札時点の日時です。</div>
      </div>
      <div className="item">
        <div className="item-title">出品状況</div>
        <div className="item-body">
          競り情報の、現在のステータスを表しており、以下の種類があります。
          <ul>
            <li>
              落札済み :
              入札または、即決で落札されている状態です。CSV出力は「落札済み」のみ対象です。
            </li>
          </ul>
        </div>
      </div>
      <div className="item">
        <div className="item-title">価格</div>
        <div className="item-body">競り終了時の価格です。</div>
      </div>
      <div className="item">
        <div className="item-title">開始価格</div>
        <div className="item-body">オークション開始時の商品価格です。</div>
      </div>
      <div className="item">
        <div className="item-title">即決価格</div>
        <div className="item-body">即決価格に設定されている価格です。</div>
      </div>
      <div className="item">
        <div className="item-title">クール区分</div>
        <div className="item-body">
          出品登録時に選択頂いた、保冷配送の設定です。
          <br />
          常温、冷蔵、冷凍の3種類があります。
        </div>
      </div>
      <div className="item">
        <div className="item-title">送料区分</div>
        <div className="item-body">
          配送料の負担についての項目です。
          <br />
          以下の3種類があります。
          <ul>
            <li>発払 : 送料無料 (出品者が負担)</li>
            <li>着払 : 着払い (落札者が負担)</li>
            <li>元払 : 元払い (落札者が負担)</li>
          </ul>
        </div>
      </div>
      <div className="item">
        <div className="item-title">落札ユーザーの配達時間帯</div>
        <div className="item-body">
          落札者によって選択された、送付先情報の配達時間帯の値です。
          <ul>
            <li>08〜12 : 8〜12時に配達を希望しています。</li>
            <li>14〜16 : 14〜16時に配達を希望しています。</li>
            <li>16〜18 : 16〜18時に配達を希望しています。</li>
            <li>18〜20 : 18〜20時に配達を希望しています。</li>
            <li>20〜21 : 20〜21時に配達を希望しています。</li>
          </ul>
        </div>
      </div>
      <div className="item">
        <div className="item-title">お届け先電話番号</div>
        <div className="item-body">
          落札者の送付先情報の電話番号です。ハイフン付き3桁+4桁+4桁(XXX-YYYY-ZZZZ)の形式で出力されます。
        </div>
      </div>
      <div className="item">
        <div className="item-title">お届け先郵便番号</div>
        <div className="item-body">
          落札者のお届け先郵便番号です。
          <br />
          ハイフン付き3桁+4桁(XXX-YYYY)の形式で出力されます。
        </div>
      </div>
      <div className="item">
        <div className="item-title">お届け先住所</div>
        <div className="item-body">
          落札者のお届け先住所です。
          <br />
          都道府県から開始されます。
        </div>
      </div>
      <div className="item">
        <div className="item-title">お届け先名</div>
        <div className="item-body">落札者のお名前です。</div>
      </div>
      <div className="item">
        <div className="item-title">品名1</div>
        <div className="item-body">
          送付用の品名です。
          <br />
          一律、「果物」と出力されます。
        </div>
      </div>
      <div className="item">
        <div className="item-title">詳細</div>
        <div className="item-body">
          競りの説明です。改行は、半角スペースに変換されて出力されます。
        </div>
      </div>
      <div className="item">
        <div className="item-title">出品商品ID</div>
        <div className="item-body">FruitSaleで管理するシステムIDです。</div>
      </div>
      <div className="item">
        <div className="item-title">StripeChargeID・StripePaymentID</div>
        <div className="item-body">
          Stripe（ 決済サービス ）の決済IDです。
          <br />
          Stripeの画面上で検索フォームに入力すると決済が検索可能です。
          <br />
          StripeからCSVを出力する際、ユーザーによっていずれかの値が一意のIDとして出力可能です。
        </div>
      </div>
    </div>
  );
};
