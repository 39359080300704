import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';

import {SalesPagingParams, GetChatMessage} from '@/api/sales';

import api from '@/api';
import utils from '@/lib/utils';
import _ from 'lodash';

export type SalesState = {
  search: {
    words: string[];
  };
  pagination: Pagination;
  detail: {
    id: number;
    boughtUserId: number;
    boughtUserName: string;
    name: string;
    closedReason: number;
    displayPrice: number;
    fixPrice: number;
    explanation: string;
    weight: number;
    fishingSpot: number;
    fishingDay: string;
    isNature: boolean;
    ikejimeMethod: string;
    isIkejime: boolean;
    ikejimeWater: string;
    ikejimeTiming: string;
    saveText: string;
    expenseText: string;
    deliveryText: string;
    timingText: string;
    areaText: string;
    startPrice: string;
    postedAt: string;
    closeBidAt: string;
    size: string;
    weightAdditionalInfo: string;
    fishingSpotDetail: string;
    lastUpdatedAt: string;
    isVacuum: boolean;
    bid: boolean;
    fixed: boolean;
  };
  chat: {
    pages: {
      active: any[];
      after_distant: any[];
      after_near: any[];
      before_distant: any[];
      before_near: any[];
      first: any[];
      last: any[];
    };
    current: number;
    totalCount: number;
    totalPages: number;
  };
  comment: {
    pages: {
      active: any[];
      after_distant: any[];
      after_near: any[];
      before_distant: any[];
      before_near: any[];
      first: any[];
      last: any[];
    };
    current: number;
    totalCount: number;
    totalPages: number;
  };
};

const initialState: SalesState = {
  detail: {
    id: 0,
    name: '',
    boughtUserId: 0,
    boughtUserName: '',
    closedReason: 0,
    displayPrice: 0,
    fixPrice: 0,
    explanation: '',
    weight: 0,
    fishingSpot: 0,
    fishingDay: '',
    isNature: false,
    ikejimeMethod: '',
    isIkejime: false,
    ikejimeWater: '',
    ikejimeTiming: '',
    saveText: '',
    expenseText: '',
    deliveryText: '',
    timingText: '',
    areaText: '',
    startPrice: '',
    postedAt: '',
    closeBidAt: '',
    lastUpdatedAt: '',
    size: '',
    weightAdditionalInfo: '',
    fishingSpotDetail: '',
    isVacuum: false,
    bid: false,
    fixed: false,
  },
  search: {
    words: [],
  },
  pagination: {
    data: {
      active: [],
      after_distant: [],
      after_near: [],
      before_distant: [],
      before_near: [],
      first: [],
      last: [],
    },
    sort: '-id',
    current: 1,
    totalCount: 0,
    totalPages: 0,
    disabled: false,
  },
  chat: {
    pages: {
      active: [],
      first: [],
      last: [],
      before_distant: [],
      before_near: [],
      after_near: [],
      after_distant: [],
    },
    totalCount: 0,
    totalPages: 0,
    current: 1,
  },
  comment: {
    pages: {
      active: [],
      first: [],
      last: [],
      before_distant: [],
      before_near: [],
      after_near: [],
      after_distant: [],
    },
    totalCount: 0,
    totalPages: 0,
    current: 1,
  },
};

export const fetchSales = createAsyncThunk(
  'sales/fetchSales',
  async (arg: Omit<SalesPagingParams, 'limit'>) => {
    const res = await api.sales.salesPaging({
      limit: 10,
      page: arg.page,
      seller: arg.seller,
      closed: arg.closed,
      fixed: arg.fixed,
      sort: arg.sort,
      search: arg.search,
    });

    const pagination = {
      data: res.pages,
      totalCount: res.totalCount,
      totalPages: res.totalPages,
      current: arg.page,
      sort: arg.sort,
    };

    return pagination;
  }
);

export const fetchSale = createAsyncThunk(
  'sales/fetchSale',
  async (id: number) => {
    const res = await api.sales.getOne(id);

    return res;
  }
);

export const fetchChatMessage = createAsyncThunk(
  'sales/fetchChatMessage',
  async ({saleId, limit, page}: GetChatMessage) => {
    const {data, headers} = await api.sales.getChatMessages({
      saleId,
      limit,
      page,
    });
    return {data, headers, page};
  }
);

export const fetchCommentMessages = createAsyncThunk(
  'sales/fetchCommentMessages',
  async ({saleId, limit, page}: GetChatMessage) => {
    const {data, headers} = await api.sales.getCommentMessages({
      saleId,
      limit,
      page,
    });
    return {data, headers, page};
  }
);

const sales = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    updateSearchWordsSales: (
      state,
      {payload}: PayloadAction<{word: string}>
    ): void => {
      if (_.includes(state.search.words, payload.word)) {
        state.search.words = _.reject(state.search.words, (w: string) => {
          return w === payload.word;
        });
      } else {
        state.search.words = _.concat(state.search.words, payload.word);
      }
    },
    updateActiveSales: (
      state,
      {
        payload,
      }: PayloadAction<{
        pages: any;
        current: number;
        nextPage: number;
        totalPages: number;
      }>
    ): void => {
      const active = utils.pagination.makeRemoteActive(
        payload.pages,
        payload.current,
        payload.nextPage,
        payload.totalPages
      );

      state.pagination.data.active = active;
      state.pagination.current = payload.nextPage;
    },
    getDetail: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: number;
        sales: SalesState['pagination']['data']['active'];
      }>
    ): void => {
      const detail = _.find(payload.sales, {sale_id: payload.id});

      state.detail.id = detail.sale_id;
      state.detail.boughtUserId = detail.bought_user_id;
      state.detail.boughtUserName = detail.bought_user_name;
      state.detail.name = detail.name;
      state.detail.closedReason = detail.closed_reason;
      state.detail.displayPrice = detail.display_price;
      state.detail.fixPrice = detail.fix_price;
      state.detail.explanation = detail.explanation;
      state.detail.weight = detail.weight;
      state.detail.fishingSpot = detail.fishing_spot;
      state.detail.fishingDay = detail.fishing_day;
      state.detail.isNature = detail.is_nature;
      state.detail.ikejimeMethod = detail.method_text;
      state.detail.isIkejime = detail.is_ikejime;
      state.detail.ikejimeWater = detail.water_text;
      state.detail.ikejimeTiming = detail.ikejime_text;
      state.detail.saveText = detail.save_text;
      state.detail.expenseText = detail.expense_text;
      state.detail.deliveryText = detail.delivery_text;
      state.detail.timingText = detail.timing_text;
      state.detail.areaText = detail.area_text;
      state.detail.startPrice = detail.start_price;
      state.detail.postedAt = detail.posted_at;
      state.detail.closeBidAt = detail.close_bid_at;
      state.detail.size = detail.size;
      state.detail.isVacuum = detail.is_vacuum;
      state.detail.fishingSpotDetail = detail.fishing_spot_detail;
      state.detail.weightAdditionalInfo = detail.weight_additional_info;
      state.detail.bid = detail.bid;
      state.detail.fixed = detail.fixed;
      state.detail.lastUpdatedAt = detail.last_updated_at;
    },
    clearDetail: (state) => {
      state.detail = initialState.detail;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSales.fulfilled, (state, {payload}) => {
      state.pagination.data = payload.data;
      state.pagination.totalCount = Number(payload.totalCount);
      state.pagination.totalPages = Number(payload.totalPages);
      state.pagination.sort = payload.sort;
      state.pagination.current = payload.current;
    });
    builder.addCase(fetchSale.fulfilled, (state, {payload}) => {
      state.detail.id = payload.sale_id;
      state.detail.name = payload.name;
      state.detail.closedReason = payload.closed_reason;
      state.detail.boughtUserId = payload.bought_user_id;
      state.detail.boughtUserName = payload.bought_user_name;
      state.detail.displayPrice = payload.display_price;
      state.detail.fixPrice = payload.fix_price;
      state.detail.explanation = payload.explanation;
      state.detail.weight = payload.weight;
      state.detail.fishingSpot = payload.fishing_spot;
      state.detail.fishingDay = payload.fishing_day;
      state.detail.isNature = payload.is_nature;
      state.detail.ikejimeMethod = payload.method_text;
      state.detail.isIkejime = payload.is_ikejime;
      state.detail.ikejimeWater = payload.water_text;
      state.detail.ikejimeTiming = payload.ikejime_text;
      state.detail.saveText = payload.save_text;
      state.detail.expenseText = payload.expense_text;
      state.detail.deliveryText = payload.delivery_text;
      state.detail.timingText = payload.timing_text;
      state.detail.areaText = payload.area_text;
      state.detail.startPrice = payload.start_price;
      state.detail.postedAt = payload.posted_at;
      state.detail.closeBidAt = payload.close_bid_at;
      state.detail.size = payload.size;
      state.detail.isVacuum = payload.is_vacuum;
      state.detail.bid = payload.bid;
      state.detail.fixed = payload.fixed;
      state.detail.fishingSpotDetail = payload.fishing_spot_detail;
      state.detail.weightAdditionalInfo = payload.weight_additional_info;
      state.detail.lastUpdatedAt = payload.last_updated_at;
    });
    builder.addCase(fetchChatMessage.fulfilled, (state, {payload}) => {
      state.chat.pages = payload.data.pages;
      state.chat.current = payload.page;
      state.chat.totalCount = payload.headers['x-total-count'];
      state.chat.totalPages = payload.headers['x-total-pages'];
    });
    builder.addCase(fetchCommentMessages.fulfilled, (state, {payload}) => {
      state.comment.pages = payload.data.pages;
      state.comment.current = payload.page;
      state.comment.totalCount = payload.headers['x-total-count'];
      state.comment.totalPages = payload.headers['x-total-pages'];
    });
  },
});

export const {
  updateSearchWordsSales,
  updateActiveSales,
  getDetail,
  clearDetail,
} = sales.actions;

export default sales.reducer;
