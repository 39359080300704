import {FormikConfig} from 'formik';

import moment from 'moment';

const PRICE = {
  MIN: 50,
  MAX: 10000001,
};

const WEIGHT = {
  MIN: 0,
  MAX: 10000000,
};

// バリデーション
export const create: Pick<FormikConfig<any>, 'validate'>['validate'] = (
  values: any
) => {
  const errors: any = {};

  if (!values.imageJson.length) {
    errors.imageJson = '画像を選択してください';
  }

  if (!values.name) {
    errors.name = '商品名を入力してください';
  }

  // if (values.size) {
  //   const size: any = values.size;
  //   if (!/^[0-9]+$/.test(size)) {
  //     errors.size = '半角数字を入力してください';
  //   } else if (/^[0-9]+$/.test(size)) {
  //     if (size < 0) {
  //       errors.size = '0以上の数字を入力してください';
  //     } else if (size > 10000) {
  //       errors.size = '10,000以下の数字を入力してください';
  //     }
  //   }
  // }

  if (values.weight) {
    const weight: any = values.weight;
    if (!/^[0-9]+$/.test(weight)) {
      errors.weight = '半角数字を入力してください';
    } else {
      if (weight < WEIGHT.MIN) {
        errors.weight = `${WEIGHT.MIN}以上の数字を入力してください`;
      } else if (weight > WEIGHT.MAX) {
        errors.weight = `${WEIGHT.MAX.toLocaleString()}以下の数字を入力してください`;
      }
    }
  }

  // 処理方法
  // if (!values.cleaningMethod) {
  //   errors.cleaningMethod = '処理方法を入力してください';
  // }

  // 保管方法
  // if (!values.savingMethod) {
  //   errors.savingMethod = '保管方法を入力してください';
  // }

  // 配送料の負担
  if (!values.shippingExpense) {
    errors.shippingExpense = '配送料の負担を入力してください';
  }

  if (values.shippingExpense === 3 && !values.shippingSize) {
    errors.shippingSize = '配送サイズ区分を入力してください';
  }

  // 配送方法
  if (!values.deliveryMethod) {
    errors.deliveryMethod = '配送方法の負担を入力してください';
  }

  // 配送までの日数
  if (!values.shippingTiming) {
    errors.shippingTiming = '配送までの日数を入力してください';
  }

  // 配送元の地域
  if (!values.area) {
    errors.area = '発送元の地域を入力してください';
  }

  // 価格
  if (!values.startPrice) {
    errors.startPrice = '出品時の開始価格を入力してください';
  } else {
    const price: any = Number(values.startPrice);

    if (price < PRICE.MIN) {
      errors.startPrice = `${PRICE.MIN.toLocaleString()}円以上の金額を入力してください`;
    } else if (price >= PRICE.MAX) {
      const p = PRICE.MAX - 1;
      errors.startPrice = `${p.toLocaleString()}円以下の金額を入力してください`;
    } else if (!/^[0-9]+$/.test(price)) {
      errors.startPrice = '半角数字を入力してください';
    }
  }

  let startPrice: any = PRICE.MIN;
  startPrice = Number(startPrice);

  if (values.startPrice && values.startPrice > PRICE.MIN) {
    startPrice = Number(values.startPrice);
  }

  // 即決価格
  if (values.fixPrice) {
    const fixPrice: any = Number(values.fixPrice);

    if (startPrice >= PRICE.MAX) {
      const p = PRICE.MAX - 1;
      errors.fixPrice = `${p.toLocaleString()}円以下の金額を入力してください`;
    } else if (fixPrice <= startPrice) {
      const p: number = startPrice + 1;
      errors.fixPrice = `${p.toLocaleString()}円以上の金額を入力してください`;
    } else if (fixPrice > PRICE.MAX) {
      errors.fixPrice = `${PRICE.MAX.toLocaleString()}円以下の金額を入力してください`;
    } else if (!/^[0-9]+$/.test(fixPrice)) {
      errors.fixPrice = '半角数字を入力してください';
    }
  }

  // 配送元の地域
  if (!values.closeBidMin) {
    errors.closeBidMin = '出品期限を入力してください';
  }

  // 出品商品説明
  if (!values.explanation) {
    errors.explanation = '出品商品説明を入力してください';
  }

  // 繰り返し回数
  if (values.capacity !== 0) {
    if (values.capacity) {
      const capacity: any = values.capacity;
      if (!/^[0-9]+$/.test(capacity)) {
        errors.capacity = '半角数字を入力してください';
      }
    } else {
      errors.capacity = '繰り返し回数を入力してください';
    }
  }

  // 繰り返し期限が過去の日時になっている場合、エラーをだす
  const d = moment().isAfter([
    values.repeatLimitDate.year,
    values.repeatLimitDate.month - 1,
    values.repeatLimitDate.day,
  ]);

  if (d) {
    errors.repeatLimitDate = '正しい日付を入力してください';

    if (!values.repeatLimitDate.month || !values.repeatLimitDate.day) {
      errors.repeatLimitDate = '正しい日付を入力してください';
    }
  }

  const lastDay = moment([
    values.repeatLimitDate.year,
    values.repeatLimitDate.month - 1,
  ]).daysInMonth();

  if (lastDay < values.repeatLimitDate.day) {
    errors.repeatLimitDate = '正しい日付を入力してください';
  }

  // 追加
  // 出品者側保管方法
  // if (!values.saveMethodSeller) {
  //   errors.saveMethodSeller = '出品者側保管方法を入力してください';
  // }

  return errors;
};
