import {get} from '@/lib/utils/env';
import utils from '@/lib/utils';

const {SITE_TYPE} = get();

export type CreateUsersRate = {
  dist_user_id: number;
  sale_id: number;
  point: number;
  comment: string;
};

/**
 * me取得
 */
export const getMe = async () => {
  const res = await utils.axios.api().get(`/v1/${SITE_TYPE}/me`);

  return res.data;
};

/**
 * レーティング
 * @param userId 落札者のユーザーID (レビュー対象のユーザーID)
 */
export const rate = async (userId: number, b: CreateUsersRate) => {
  const res = await utils.axios
    .api()
    .post(`/v1/${SITE_TYPE}/users/${userId}/rate`, b);

  return res.data;
};

interface RateCollectBody {
  rating_targets: Array<{
    sale_id: number;
    dist_user_id: number;
  }>;
  point: number;
  comment: string;
}

/**
 *
 */
export const rateCollect = async (meId: number, body: RateCollectBody) => {
  const res = await utils.axios
    .api()
    .post(`/v1/${SITE_TYPE}/users/${meId}/rate/collect`, body);

  return res.data;
};
