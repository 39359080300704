import {get} from '@/lib/utils/env';
import utils from '@/lib/utils';
import {AxiosPromise} from 'axios';

const {SITE_TYPE} = get();

export const putStocksAutoStockId = ({
  stockId,
  ...params
}: PutStocksAutoStockIdParams): AxiosPromise<PutStocksAutoStockIdData> =>
  utils.axios
    .api()
    .put(
      `/v1/${SITE_TYPE}/stocks_auto/${stockId}`,
      utils.mapKeysSnakeCase.default(params)
    );
