import React from 'react';
import {Checkbox, Icon, Popup, Button} from 'semantic-ui-react';
import {Field, FormikProps} from 'formik';

import {Table} from '@/components';
import utils from '@/lib/utils';
import resources from '@/lib/resources';
import {RootState} from '@/app/rootReducer';
import Rating from 'react-rating';

import './column.scss';

// maxPrice (2147483647) はシステム上で保持できる最大値(int32)
const maxPrice = 2147483647;

const env = utils.env.get();

export const makeColumns = (
  sales: RootState['sales'],
  loading: boolean,
  formProps: FormikProps<any>,
  changeSort: (sort: string, current: number) => void
) => [
  {
    Header: '',
    accessor: 'selection',
    width: 40,
    Cell: ({cell}: {cell: any; column: any}) => {
      return (
        <Field
          component={() => {
            const status = resources.sell.getStatus(
              cell.value,
              cell.row.original.bid,
              cell.row.original.fixed
            );
            return (
              <Checkbox
                onChange={() => {
                  const selections = formProps.values.selections;
                  const ratingTargets = formProps.values.ratingTargets;

                  // チェックが入っていなければ、チェックを入れる
                  if (!selections.includes(cell.row.values.sale_id)) {
                    selections.push(cell.row.values.sale_id);
                    formProps.setFieldValue('selections', selections);
                  } else {
                    const _selections = selections.filter((selection: any) => {
                      return cell.row.values.sale_id !== selection;
                    });
                    formProps.setFieldValue('selections', _selections);
                  }

                  const {sale_id, bought_user_id} = cell.row.original;
                  const ratingTargetsBySaleId = ratingTargets.map(
                    (ratingTarget: any) => ratingTarget.sale_id
                  );

                  if (!ratingTargetsBySaleId.includes(sale_id)) {
                    ratingTargets.push({
                      sale_id,
                      dist_user_id: bought_user_id,
                    });
                    formProps.setFieldValue('ratingTargets', ratingTargets);
                  } else {
                    const _ratingTargets = ratingTargets.filter(
                      (ratingTarget: any) => {
                        return ratingTarget.sale_id !== sale_id;
                      }
                    );
                    formProps.setFieldValue('ratingTargets', _ratingTargets);
                  }
                }}
                style={{
                  backgroundColor: '#e0dede',
                  borderRadius: 4,
                }}
                checked={formProps.values.selections.includes(
                  cell.row.values.sale_id
                )}
                // 落札済みでない場合、CSV出力できないようにする
                disabled={status.value !== 'done'}
              />
            );
          }}
          name={`selection_${cell.row.index}`}
        />
      );
    },
  },
  {
    Header: () => {
      return (
        <Table.Sort
          value="ID"
          accessor="sale_id"
          disabled={loading}
          updateCurrent={changeSort}
          current={sales.pagination.current}
          currentSort={sales.pagination.sort}
        />
      );
    },
    accessor: 'sale_id',
    width: 80,
  },
  {
    Header: () => '複製',
    accessor: '複製',
    width: 140,
    Cell: ({
      cell: {
        row: {
          values: {sale_id},
        },
      },
    }: any) => (
      <a
        href={`${env.WEB_APP_HOST}/sell?id=${sale_id}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        複製
      </a>
    ),
  },
  {
    Header: () => {
      return (
        <Table.Sort
          value="競りタイトル"
          accessor="name"
          disabled={loading}
          updateCurrent={changeSort}
          current={sales.pagination.current}
          currentSort={sales.pagination.sort}
        />
      );
    },
    accessor: 'name',
    width: 400,
    Cell: ({cell}: {cell: any; column: any}) => {
      const detail = cell.row.original;

      return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={`${env.WEB_APP_HOST}/sale/${detail.sale_id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {detail.name}
        </a>
      );
    },
  },
  {
    Header: '落札者からの評価',
    accessor: 'rating_point_by_seller_user',
    width: 246,
    Cell: ({cell}: {cell: any; column: any}) => {
      const {rating_point_by_bought_user, rating_comment_by_bought_user} =
        cell.row.original;

      return (
        <>
          <Rating
            initialRating={rating_point_by_bought_user}
            emptySymbol={<Icon name="star" color="grey" />}
            fullSymbol={<Icon name="star" color="yellow" />}
            readonly
          />

          {rating_comment_by_bought_user ? (
            <Popup
              content={rating_comment_by_bought_user}
              disabled={!rating_comment_by_bought_user}
              trigger={
                <Button circular className="popup-button">
                  コメントを確認する
                </Button>
              }
              on="click"
              position="top left"
            />
          ) : null}
        </>
      );
    },
  },
  {
    Header: '自分がつけた評価',
    accessor: 'rating_point_by_bought_user',
    width: 246,
    Cell: ({cell}: {cell: any; column: any}) => {
      const {rating_point_by_seller_user, rating_comment_by_seller_user} =
        cell.row.original;

      return (
        <>
          <Rating
            initialRating={rating_point_by_seller_user}
            emptySymbol={<Icon name="star" color="grey" />}
            fullSymbol={<Icon name="star" color="yellow" />}
            readonly
          />

          {rating_comment_by_seller_user ? (
            <Popup
              content={rating_comment_by_seller_user}
              disabled={!rating_comment_by_seller_user}
              trigger={
                <Button circular className="popup-button">
                  コメントを確認する
                </Button>
              }
              on="click"
              position="top left"
            />
          ) : null}
        </>
      );
    },
  },
  {
    Header: '出品状況',
    accessor: 'closed_reason',
    width: 160,
    Cell: ({cell}: {cell: any; column: any}) => {
      const status = resources.sell.getStatus(
        cell.value,
        cell.row.original.bid,
        cell.row.original.fixed
      );

      return (
        <div>
          <span className={`status-${status.value}`}>{status.title}</span>
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <Table.Sort
          value="現在価格"
          accessor="display_price"
          disabled={loading}
          updateCurrent={changeSort}
          current={sales.pagination.current}
          currentSort={sales.pagination.sort}
        />
      );
    },
    accessor: 'display_price',
    width: 140,
    Cell: ({cell}: {cell: any; column: any}) => {
      return (
        <div className="text-bold">{`￥${cell.value.toLocaleString()}`}</div>
      );
    },
  },
  {
    Header: () => {
      return (
        <Table.Sort
          value="落札者"
          accessor="bought_user_name"
          disabled={loading}
          updateCurrent={changeSort}
          current={sales.pagination.current}
          currentSort={sales.pagination.sort}
        />
      );
    },
    accessor: 'bought_user_name',
    width: 250,
    Cell: ({cell}: {cell: any; column: any}) => {
      const detail = cell.row.original;

      // ユーザーIDが存在しなければ表示しない
      if (!detail.bought_user_id) {
        return null;
      }

      return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={`${env.WEB_APP_HOST}/users/${detail.bought_user_id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {detail.bought_user_name}
        </a>
      );
    },
  },
  {
    Header: () => {
      return (
        <Table.Sort
          value="出品日時"
          accessor="posted_at"
          disabled={loading}
          updateCurrent={changeSort}
          current={sales.pagination.current}
          currentSort={sales.pagination.sort}
        />
      );
    },
    accessor: 'posted_at',
    width: 220,
    Cell: ({cell}: {cell: any; column: any}) => {
      return (
        <div>
          {utils.date.format(
            cell.value,
            'YYYY.MM.DD HH:mm',
            'YYYY.MM.DD (ddd) HH:mm'
          )}
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <Table.Sort
          value="終了日時"
          accessor="close_bid_at"
          disabled={loading}
          updateCurrent={changeSort}
          current={sales.pagination.current}
          currentSort={sales.pagination.sort}
        />
      );
    },
    accessor: 'close_bid_at',
    width: 220,
    Cell: ({cell}: {cell: any; column: any}) => {
      return (
        <div>
          {utils.date.format(
            cell.value,
            'YYYY.MM.DD HH:mm',
            'YYYY.MM.DD (ddd) HH:mm'
          )}
        </div>
      );
    },
  },
  {
    Header: () => {
      return (
        <Table.Sort
          value="開始価格"
          accessor="start_price"
          disabled={loading}
          updateCurrent={changeSort}
          current={sales.pagination.current}
          currentSort={sales.pagination.sort}
        />
      );
    },
    accessor: 'start_price',
    width: 140,
    Cell: ({cell}: {cell: any; column: any}) => {
      return <div>{`￥${cell.value.toLocaleString()}`}</div>;
    },
  },
  {
    Header: () => {
      return (
        <Table.Sort
          value="即決価格"
          accessor="fix_price"
          disabled={loading}
          updateCurrent={changeSort}
          current={sales.pagination.current}
          currentSort={sales.pagination.sort}
        />
      );
    },
    accessor: 'fix_price',
    width: 140,
    Cell: ({cell}: {cell: any; column: any}) => {
      return cell.value !== maxPrice ? (
        <div>{`￥${cell.value.toLocaleString()}`}</div>
      ) : (
        <div>{`ー`}</div>
      );
    },
  },
];
