import {mergeDate} from '@/lib/utils/date';
import paths from '@/config/paths';
import {createStocks} from '@/api/stocks';
import notify from '@/migration/notification';

type StockParams = {
  area: number;
  capacity: number;
  cleaningMethod: number;
  closeBidMin: number;
  count: number;
  deliveryMethod: number;
  explanation: string;
  // fishKind: string;
  fishingDay: string;
  // fishingSpot: number;
  // fishingSpotDetail: string;
  fixPrice?: number;
  // ikejimeMethod: number;
  // ikejimeTiming: number;
  // ikejimeWater: number;
  imageJson: string[];
  isDummy: boolean;
  // isIkejime: boolean;
  isLandAfterFix: boolean;
  // isNature: boolean;
  // isVacuum: boolean;
  repeatLimitDate: string;
  // savingMethod: number;
  sellerUserId: number;
  shippingExpense: number;
  shippingSize: number;
  shippingTiming: number;
  size: number;
  startPrice: number;
  weight?: number;
  weightAdditionalInfo: string;

  // 追加
  name: string;
  breed: string;
  isUnevenness: boolean;
  producer: string;
  grade: number;
  origin: number;
  originAdditionalInfo: string;
  averageSugarContent: number;
  harvestDate: string;
  harvestTiming: number;
  saveMethodUser: number;
  saveMethodSeller: number;
  saveMethodUserAdditionalInfo: string;
  cultivationMethod: number;
  soil: string;
  soilAdditionalInfo: string;
  pesticides: string;
  pesticidesAdditionalInfo: string;
  genetic: number;
  jas: number;
  individualObligations: string;
  globalGap: string | null;
};

const toInt = (str: string) => {
  return str ? parseInt(str, 10) : 0;
};

const createParams = (values: any) => {
  let fishingDay = '';

  if (
    values.fishingDate.year &&
    values.fishingDate.month &&
    values.fishingDate.day
  ) {
    fishingDay = mergeDate(
      values.fishingDate.year,
      values.fishingDate.month,
      values.fishingDate.day,
      'YYYY-MM-DD'
    );
  }

  const soilInfo = values.soilAdditionalInfo
    ? values.soilAdditionalInfo
        .map((e: any, i: number) => (e ? i.toString() : undefined))
        .filter((f: undefined | string) => f !== undefined)
        .join(',')
    : null;

  const pesticidesInfo = values.pesticidesAdditionalInfo
    ? values.pesticidesAdditionalInfo
        .map((e: any, i: number) => {
          if (values.pesticides === null) return undefined;
          switch (values.pesticides) {
            case 2:
              if (i === 0) {
                return e.toString();
              } else {
                return undefined;
              }
            case 3:
              if (i !== 0 && e) return i.toString();
              else {
                return undefined;
              }
            default:
              return undefined;
          }
        })
        .filter((f: undefined | string) => f !== undefined)
        .join(',')
    : null;

  const stockParams: StockParams = {
    area: values.area,
    capacity: toInt(values.capacity),
    cleaningMethod: values.cleaningMethod,
    closeBidMin: values.closeBidMin * 60,
    count: 0,
    deliveryMethod: values.deliveryMethod,
    explanation: values.explanation,
    // fishKind: values.fishKind,
    fishingDay,
    // fishingSpot: toInt(values.fishingSpot),
    // fishingSpotDetail: values.fishingSpotDetail,
    // ikejimeMethod: values.ikejimeMethod,
    // ikejimeTiming: values.ikejimeTiming,
    // ikejimeWater: values.ikejimeWater,
    imageJson: values.imageJson,
    isDummy: values.isDummy,
    // isIkejime: values.isIkejime,
    isLandAfterFix: values.isLandAfterFix,
    // isNature: values.isNature,
    // isVacuum: values.isVacuum,
    repeatLimitDate: mergeDate(
      values.repeatLimitDate.year,
      values.repeatLimitDate.month,
      values.repeatLimitDate.day,
      'YYYY-MM-DD'
    ),
    // savingMethod: values.savingMethod,
    sellerUserId: values.sellerUserId,
    shippingExpense: values.shippingExpense,
    shippingSize: values.shippingSize,
    shippingTiming: values.shippingTiming,
    size: toInt(values.size),
    startPrice: toInt(values.startPrice),
    weightAdditionalInfo: values.weightAdditionalInfo,

    // 追加
    name: values.name,
    breed: values.breed,
    isUnevenness: values.isUnevenness,
    producer: values.producer,
    grade: values.grade,
    origin: values.origin,
    originAdditionalInfo: values.originAdditionalInfo,
    averageSugarContent: parseFloat(values.averageSugarContent),
    harvestTiming: values.harvestTiming,
    harvestDate: fishingDay,
    saveMethodUser: values.saveMethodUser,
    saveMethodSeller: values.saveMethodSeller,
    saveMethodUserAdditionalInfo: values.saveMethodUserAdditionalInfo,
    cultivationMethod: values.cultivationMethod,
    soil: values.soil,
    soilAdditionalInfo: soilInfo,
    pesticides: values.pesticides,
    pesticidesAdditionalInfo: pesticidesInfo,
    genetic: values.genetic,
    jas: values.jas,
    individualObligations: values.individualObligations,
    globalGap: values.globalGap ? '認証' : null,
  };

  if (values.weight) stockParams.weight = toInt(values.weight);

  if (values.fixPrice) stockParams.fixPrice = toInt(values.fixPrice);

  return {stockParams};
};

// TODO 型づけ
export const submit = async (
  value: any,
  actions: any,
  history: any,
  setModal: any
) => {
  try {
    actions.setSubmitting(true);

    const {stockParams} = createParams(value);

    await createStocks(stockParams);

    notify('自動再出品登録しました。', 'success');
    history.push(paths.stocks.main);
  } catch (error: any) {
    console.log(error);
    // notify(error.message, 'error');
  } finally {
    actions.setSubmitting(false);
    setModal(false);
  }
};
