import {get} from '@/lib/utils/env';
import utils from '@/lib/utils';
import {AxiosPromise} from 'axios';

const {SITE_TYPE} = get();

export const getStocksStockId = ({
  stockId,
}: GetStocksStockIdParams): AxiosPromise<GetStocksStockIdData> =>
  utils.axios.api().get(`/v1/${SITE_TYPE}/stocks/${stockId}`);

export const putStocksStockId = ({
  stockId,
  ...params
}: any): AxiosPromise<PutStocksStockIdData> =>
  utils.axios
    .api()
    .put(
      `/v1/${SITE_TYPE}/stocks/${stockId}`,
      utils.mapKeysSnakeCase.default(params)
    );
