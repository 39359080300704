import {get} from '@/lib/utils/env';
import utils from '@/lib/utils';
import {AxiosPromise} from 'axios';

const {SITE_TYPE} = get();

export const getShippingCosts = (
  userId: number
): AxiosPromise<ShippingCosts> => {
  return utils.axios.api().get(`/v1/${SITE_TYPE}/shipping_costs/${userId}`);
};

export const updateShippingCosts = (
  userId: number,
  body: UpdateSippingCostsBody
) => {
  return utils.axios
    .api()
    .put(`/v1/${SITE_TYPE}/shipping_costs/${userId}`, body);
};
