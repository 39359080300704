import React, {useState} from 'react';
import {Formik, FormikProps, Field} from 'formik';
import {useSelector} from 'react-redux';
import {RootState} from '@/app/rootReducer';
import Modal from '@/migration/modal';

import {Table, Pagination, Layouts, Button, Form} from '@/components';
import {useSalesTable, CLOSED_SORT_COND, DEFAULT_SORT_COND} from './hooks';
import {Props} from './types';
import {makeColumns} from './Columns';
import {CSVExplanation} from './Explanation';

import RatingModal from './RatingModal';
import Header from './Header';

import './index.scss';

export const SalesMain: React.FC<Props> = () => {
  const {sales, me} = useSelector((state: RootState) => ({
    sales: state.sales,
    me: state.me,
  }));
  const [isBid, setIsBid] = useState<boolean | undefined>(false);
  const [ratingModal, setRatingModal] = useState(false);
  const [showExplanationModal, setExplanationModal] = useState(false);
  const handleClick = () => {
    setExplanationModal(true);
  };
  const handleCloseModal = () => {
    setExplanationModal(false);
  };

  const initialSearchValues = {
    searchWord: '',
  };

  const sort = isBid ? CLOSED_SORT_COND : DEFAULT_SORT_COND;
  const {currentPagination, searchWord, changeSort, isLoading, isPaging} =
    useSalesTable({sort, isBid});

  return (
    <div className="a__sales-main">
      <div className="b__title c__after-title">
        <h2 className="title">出品商品一覧</h2>
      </div>
      <div className="b__search">
        <Layouts.Search>
          <Formik
            initialValues={initialSearchValues}
            validate={() => {}}
            onSubmit={currentPagination}
          >
            {(formProps: FormikProps<any>) => {
              return (
                <form>
                  <div className="b__search">
                    <span className="c__label">検索ワード</span>
                    <Field
                      name="searchWord"
                      component={Form.Input.Block}
                      placeholder="競りタイトルで検索"
                      style={{width: '400px'}}
                      search={{
                        words: sales.search.words,
                        label: '検索ワード',
                        onKeyDown: (value: string) => {
                          if (!value.trim()) return;
                          searchWord(value);
                          formProps.resetForm();
                        },
                        removeWords: (value: string) => {
                          searchWord(value);
                          formProps.resetForm();
                        },
                      }}
                      dark
                    />
                  </div>
                  <div className="b__action">
                    <Button.Block
                      color="main"
                      onClick={() => {
                        formProps.handleSubmit();
                      }}
                    >
                      検索する
                    </Button.Block>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Layouts.Search>
      </div>
      <div className="b__sales">
        <Formik
          initialValues={{selections: [], ratingTargets: []}}
          validate={() => {}}
          onSubmit={() => {}}
        >
          {(formProps: FormikProps<any>) => {
            return (
              <>
                <Header
                  isLoading={isLoading}
                  formProps={formProps}
                  sales={sales}
                  handleClick={handleClick}
                  setIsBid={setIsBid}
                  setModal={setRatingModal}
                />
                <Modal
                  className="w__explanation-modal"
                  handleCloseModal={handleCloseModal}
                  isModal={showExplanationModal}
                  ModalBody={CSVExplanation}
                />
                <Table.Main
                  columns={makeColumns(sales, isLoading, formProps, changeSort)}
                  data={sales.pagination.data.active}
                  loading={isLoading}
                />
                <Pagination
                  totalCount={sales.pagination.totalCount}
                  current={sales.pagination.current}
                  updateCurrent={(current: number) => {
                    currentPagination({
                      sort: sales.pagination.sort,
                      current,
                    });
                  }}
                  disabled={isPaging}
                />

                <RatingModal
                  sourceUserId={me.id}
                  ratingTargets={formProps.values.ratingTargets}
                  ratingModal={ratingModal}
                  setRatingModal={setRatingModal}
                  parentFormProps={formProps}
                  isBid={isBid}
                />
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
