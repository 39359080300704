export const columns = [
  {
    accessor: 'id',
    Header: 'ID',
  },
  {
    accessor: 'name',
    disableSortBy: true,
    Header: '商品名',
  },
  {
    accessor: 'createdAt',
    Header: '出品日時',
  },
  {
    accessor: 'closedReason',
    disableSortBy: true,
    Header: '出品状況',
  },
  {
    accessor: 'startPrice',
    Header: '開始価格',
  },
  {
    accessor: 'fixPrice',
    Header: '即決価格',
  },
];
