export interface State {
  imageJson: string[];
  size: string | number;
  weight: string | number;
  weightAdditionalInfo: string;
  // fishingSpot: string | number;
  // fishingSpotDetail: string;
  fishingDate: {
    year: string | number;
    month: string | number;
    day: string | number;
  };
  isLandAfterFix: boolean;
  // isNature: boolean;
  // ikejimeMethod: string | number;
  // isIkejime: boolean;
  // ikejimeWater: string | number;
  cleaningMethod: string | number;
  // ikejimeTiming: string | number;
  savingMethod: string | number;
  isVacuum: boolean;
  shippingExpense: string | number;
  shippingSize: string | number;
  deliveryMethod: string | number;
  shippingTiming: string | number;
  area: string | number;
  startPrice: string | number;
  fixPrice: string | number;
  closeBidMin: string | number;
  explanation: string;
  count: number;
  capacity: string | number;
  repeatLimitDate: {
    year: string | number;
    month: string | number;
    day: string | number;
  };

  // 追加
  name: string;
  breed: string;
  isUnevenness: boolean;
  producer: string;
  grade: number;
  origin: number;
  originAdditionalInfo: string;
  averageSugarContent: number;
  harvestDate: string;
  harvestTiming: number;
  saveMethodUser: number;
  saveMethodSeller: number;
  saveMethodUserAdditionalInfo: string;
  cultivationMethod: number;
  soil: number;
  pesticides: number;
  genetic: number;
  jas: number;
  individualObligations: string;
  globalGap: string;
}

export const initialState: State = {
  imageJson: [],
  name: '',
  size: '',
  weight: '',
  weightAdditionalInfo: '',
  // fishingSpot: '',
  // fishingSpotDetail: '',
  fishingDate: {
    year: '',
    month: '',
    day: '',
  },
  isLandAfterFix: false,
  // isNature: true,
  // ikejimeMethod: '',
  // isIkejime: false,
  // ikejimeWater: '',
  cleaningMethod: '',
  // ikejimeTiming: '',
  savingMethod: '',
  isVacuum: false,
  shippingExpense: '',
  shippingSize: 60,
  deliveryMethod: '',
  shippingTiming: '',
  area: '',
  startPrice: '',
  fixPrice: '',
  closeBidMin: '',
  explanation: '',
  capacity: '',
  count: 0,
  repeatLimitDate: {
    year: '',
    month: '',
    day: '',
  },

  // 追加
  breed: '',
  isUnevenness: false,
  producer: '',
  grade: 0,
  origin: 0,
  originAdditionalInfo: '',
  averageSugarContent: 0,
  harvestDate: '',
  harvestTiming: 0,
  saveMethodUser: 0,
  saveMethodSeller: 0,
  saveMethodUserAdditionalInfo: '',
  cultivationMethod: 0,
  soil: 0,
  pesticides: 0,
  genetic: 0,
  jas: 0,
  individualObligations: '',
  globalGap: '',
};
