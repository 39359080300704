import * as _utils from './_utils';

const dev: () => Env = () => {
  const envcode: Envcode = 'dev';

  return {
    NAME: '開発環境',
    COGNITO_REGION: 'ap-northeast-1',
    COGNITO_USER_POOL_ID: 'ap-northeast-1_kTg8o2iYc',
    COGNITO_CLIENT_ID: '7idpc79ied61j2j4fsd0otkp87',
    COGNITO_DOMAIN:
      'https://fruitsale-dev.auth.ap-northeast-1.amazoncognito.com',
    COGNITO_REDIRECT_URI: 'https://dev.vendor.fruitsale.jp/signin',
    API_GO_ENDPOINT: 'https://dev.api.fruitsale.jp',
    SITE_TYPE: 'fruit',
    ENVCODE: envcode,
    TITLE: _utils.getName(envcode),
    WEB_APP_HOST: 'https://dev.fruitsale.jp',
  };
};

export default dev();
